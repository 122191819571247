html {
  box-sizing: border-box;
  background-color: #282c34;
  color: white;
}

*, *::before, *::after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}

.App {
  width: 960px;
  margin: 0 auto;
  padding: 20px;
}

nav ul {
  list-style: none;
  display: flex;
  background-color: #14161A;
  margin-bottom: 20px;
}

nav ul li {
  padding: 20px;
}

nav ul li a {
  color: #61dafb;
  text-decoration: none;
}

a {
  color: #61dafb;
}

.current {
  border-bottom: 4px solid white;
}

h1 {
  margin-bottom: 20px;
}

p {
  margin-bottom: 15px;
}